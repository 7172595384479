import axios from 'axios';

export const backendApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

if (process.env.NODE_ENV !== 'production') {
  backendApiInstance.defaults.headers.Authorization = `Bearer ${process.env.REACT_APP_JWT}`;
}

export const setToken = (newToken: string) => {
  window.sessionStorage.setItem('token', newToken);
  backendApiInstance.interceptors.request.use(config => {
    config.headers.set('Authorization', `Bearer ${newToken}`);
    return config;
  });
};
