import { useReducer } from 'react';
import DownloadProgressModel from './DownloadProgressModel';
import ConfirmationModal from './ConfirmationModal';
import { SelectedVariantIdGroup } from '../../../services/Request.types';

enum CURRENT_STEP {
  CONFIRMATION = 'confirmation',
  DOWNLOADING = 'downloading',
}

type CurrentStepAction =
  | {
      type: 'set';
      payload: CURRENT_STEP;
    }
  | { type: 'reset' };

const currentStepReducer = (state: CURRENT_STEP, action: CurrentStepAction) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'reset':
      return CURRENT_STEP.CONFIRMATION;
    default:
      return state;
  }
};

/**
 * Starts with confirmation modal, then progress modal and lastly success modal
 */
export default function ImportConfirmationModal({
  onCancel,
  selectedVariants,
  onSuccess,
}: {
  onSuccess?: () => void;
  onCancel?: () => void;
  selectedVariants: SelectedVariantIdGroup[];
}) {
  const [currentStep, dispatchCurrentStep] = useReducer(
    currentStepReducer,
    CURRENT_STEP.CONFIRMATION,
  );

  const selectedProducts = new Set<string>(
    selectedVariants.map(([productId]) => productId),
  );

  if (currentStep === CURRENT_STEP.DOWNLOADING) {
    return (
      <DownloadProgressModel
        isOpen
        selectedVariants={selectedVariants}
        onComplete={() => {
          onSuccess?.();
        }}
      />
    );
  }

  if (currentStep === CURRENT_STEP.CONFIRMATION) {
    return (
      <ConfirmationModal
        totalVariants={selectedVariants.length}
        totalProducts={selectedProducts.size}
        onCancel={onCancel}
        onConfirm={() =>
          dispatchCurrentStep({
            type: 'set',
            payload: CURRENT_STEP.DOWNLOADING,
          })
        }
      />
    );
  }

  throw Error('Invalid Option');
}
