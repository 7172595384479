import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useSaveStoreTokenMutation } from '../../services/mutations.service';
import FlowbiteModal from '../../components/FlowbiteModal';

export default function StoreTokenModal() {
  const [open, setOpen] = useState(true);
  const mutation = useSaveStoreTokenMutation();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <FlowbiteModal
      isOpen={open}
      data-testid='store-token-modal'
      onClose={closeModal}
    >
      {/* Modal header */}
      <div className='mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5 dark:border-gray-600'>
        <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
          Enter your token
        </h3>
        <button
          type='button'
          className='ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white'
          onClick={closeModal}
        >
          <svg
            aria-hidden='true'
            className='size-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
          <span className='sr-only'>Close modal</span>
        </button>
      </div>
      {/* Modal body */}
      <form
        onSubmit={e => {
          e.preventDefault();
          const formData = new FormData(e.target as HTMLFormElement);
          mutation.mutate(formData.get('token') as string, {
            onSuccess: closeModal,
          });
        }}
      >
        <div className='mb-4 grid gap-4'>
          <div>
            <label htmlFor='token'>
              <span className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'>
                Token
              </span>
              <input
                data-testid='token-input'
                type='text'
                name='token'
                id='token'
                autoComplete='off'
                className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500'
              />
            </label>
            <p
              className='mt-1 text-sm text-gray-500 dark:text-gray-300'
              id='token_input_help'
            >
              <a
                href='/'
                className='font-medium text-blue-600 hover:underline dark:text-blue-500'
              >
                Steps to find it
              </a>
            </p>
          </div>
        </div>
        <div className='flex items-center justify-center space-x-4'>
          <button
            data-testid='token-submit-btn'
            type='submit'
            disabled={mutation.isPending}
            className={classNames(
              'w-full rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800',
              mutation.isPending && 'inline-flex items-center justify-center',
            )}
          >
            {mutation.isPending ? (
              <>
                <svg
                  aria-hidden='true'
                  role='status'
                  className='me-3 inline size-4 animate-spin text-white'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='#E5E7EB'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentColor'
                  />
                </svg>
                Loading...
              </>
            ) : (
              <>Save</>
            )}
          </button>
        </div>
      </form>
    </FlowbiteModal>
  );
}
