import { useEffect } from 'react';
import FlowbiteModal from '../../../components/FlowbiteModal';
import { useSaveProductSelectionMutation } from '../../../services/mutations.service';
import { SelectedVariantIdGroup } from '../../../services/Request.types';

export type DownloadProgressModelProps = {
  isOpen: boolean;
  selectedVariants: SelectedVariantIdGroup[];
  onComplete?: () => void;
};

export default function DownloadProgressModel({
  isOpen,
  selectedVariants,
  onComplete,
}: DownloadProgressModelProps) {
  const { mutate, isPending, reset } = useSaveProductSelectionMutation();

  useEffect(() => {
    mutate(selectedVariants, {
      onSuccess: () => {
        onComplete?.();
      },
    });

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate, reset]);

  return (
    <FlowbiteModal
      isOpen={isOpen}
      onClose={onComplete}
      closable={false}
      data-testid='download-progress-modal'
    >
      <svg
        className='mx-auto mb-4 size-12 text-gray-400 dark:text-gray-200'
        aria-hidden='true'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'
      >
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01'
        />
      </svg>
      {isPending ? (
        <span className='font-normal text-gray-500 dark:text-gray-400'>
          Loading...
        </span>
      ) : null}
    </FlowbiteModal>
  );
}
