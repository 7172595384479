import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import { useCallback, useMemo, useState } from 'react';
import {
  StoreNameContextProvider,
  StoreNameState,
} from 'contexts/StoreNameContext';
import SuspendedAppLayout from 'layout';
import ResetPasswordPage from './pages/reset-password';
import {
  ResetOtpContextProvider,
  ResetOtpState,
} from './contexts/ResetOtpContext';

function App() {
  const [storeName, setStoreName] = useState<string | null>(() =>
    sessionStorage.getItem('storeName'),
  );
  const [resetPasswordOtp, setResetPasswordOtp] = useState<{
    otp: number;
    email: string;
  } | null>(null);

  const setStoreNameWithSession: typeof setStoreName = useCallback(
    newStoreName => {
      const newData =
        typeof newStoreName === 'function'
          ? newStoreName(storeName)
          : newStoreName;
      if (newData) {
        sessionStorage.setItem('storeName', newData);
      }
      setStoreName(newStoreName);
    },
    [storeName],
  );
  const resetPasswordConsumerValue = useMemo<ResetOtpState>(
    () => [resetPasswordOtp, setResetPasswordOtp],
    [resetPasswordOtp, setResetPasswordOtp],
  );
  const storeNameConsumerValue = useMemo<StoreNameState>(
    () => [storeName, setStoreNameWithSession],
    [setStoreNameWithSession, storeName],
  );

  return (
    <ResetOtpContextProvider value={resetPasswordConsumerValue}>
      <StoreNameContextProvider value={storeNameConsumerValue}>
        <Routes>
          <Route path='login' element={<LoginPage />} />
          <Route path='reset-password' element={<ResetPasswordPage />} />
          <Route
            element={
              storeName ? <SuspendedAppLayout /> : <Navigate to='/login' />
            }
          >
            <Route index element={<HomePage />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Route>
        </Routes>
      </StoreNameContextProvider>
    </ResetOtpContextProvider>
  );
}

export default App;
