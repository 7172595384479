import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { animate } from 'framer-motion';
import { isEmpty } from 'lodash-es';
import {
  useImportedProductsQuery,
  useUnImportedProductsSelectionApi,
} from '../../services/queries.service';
import { SelectedVariantIdGroup } from '../../services/Request.types';

function ProductImports() {
  const { data: nonSucceededProductSelectionData } =
    useUnImportedProductsSelectionApi();

  const sortedData = useMemo(
    () =>
      nonSucceededProductSelectionData.sort((a, b) => {
        if (a.status === 'InProcess') {
          return b.status === a.status ? 0 : -1;
        }

        return 1;
      }),
    [nonSucceededProductSelectionData],
  );

  if (sortedData.length === 0) {
    return (
      <section
        className='bg-white dark:bg-gray-900'
        data-testid='no-imports-result'
      >
        <div className='mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16'>
          <div className='mx-auto max-w-screen-sm text-center'>
            <p className='mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl dark:text-white'>
              All Import jobs completed.
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      {sortedData.map(
        ({
          dev_product_legacy_id: devProductLegacyId,
          error,
          status,
          title,
        }) => (
          <div className='gap-y-4' key={devProductLegacyId}>
            <div className='flex flex-wrap items-center py-4'>
              <dl className='w-1/3 sm:w-1/4 lg:w-auto lg:flex-1'>
                <dt className='text-base font-medium text-gray-500 dark:text-gray-400'>
                  Product:
                </dt>
                <dd
                  className='mt-1.5 text-base font-semibold text-gray-900 dark:text-white'
                  data-testid={`title-${devProductLegacyId}`}
                >
                  {title}
                </dd>
              </dl>
              <dl className='w-1/3 sm:w-1/4 lg:w-auto lg:flex-1'>
                <dt className='text-base font-medium text-gray-500 dark:text-gray-400'>
                  Product ID:
                </dt>
                <dd className='mt-1.5 text-base font-semibold text-gray-900 dark:text-white'>
                  {devProductLegacyId}
                </dd>
              </dl>
              <dl className='w-1/3 sm:w-1/4 lg:w-auto lg:flex-1'>
                <dt className='text-base font-medium text-gray-500 dark:text-gray-400'>
                  Status:
                </dt>
                {status === 'InProcess' ? (
                  <dd className='me-2 mt-1.5 inline-flex items-center rounded bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'>
                    <svg
                      className='me-1 size-3'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z'
                      />
                    </svg>
                    In progress
                  </dd>
                ) : (
                  <dd className='me-2 mt-1.5 inline-flex items-center rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 dark:bg-red-900 dark:text-red-300'>
                    <svg
                      className='me-1 size-3'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18 17.94 6M18 18 6.06 6'
                      />
                    </svg>
                    Failed
                  </dd>
                )}
              </dl>
            </div>
            {status === 'fail' && (
              <div
                className='pb-2'
                data-testid={`errors-${devProductLegacyId}`}
              >
                <p className='mb-2 text-red-800 dark:text-red-300'>Errors:</p>
                <ul className='list-inside list-disc dark:text-yellow-300'>
                  {error
                    .filter(err => !isEmpty(err))
                    .map(err => (
                      <li key={err}>{err}</li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        ),
      )}
    </>
  );
}

export default function SuspendedProductImports() {
  const counterRef = useRef<HTMLDivElement>(null);
  const { data: productImportRes } = useImportedProductsQuery();
  const { data: nonSucceededProductSelectionData } =
    useUnImportedProductsSelectionApi();
  const [currImportedProductsCount, setCurrImportedProductsCount] = useState(0);

  useEffect(() => {
    const importScheduledProductIds = [
      ...new Set(
        (
          JSON.parse(
            localStorage.getItem('Imported Variants') ?? '[]',
          ) as SelectedVariantIdGroup[]
        ).map(([productId]) => productId as string),
      ).values(),
    ];

    const importedProductIdsTillNow = [
      ...new Set(
        productImportRes
          .filter(
            product => Boolean(product.status) && product.status === 'success',
          )
          .map(product => product.dev_product_legacy_id),
      ),
    ];

    const newCurrImportedProductsCount = importedProductIdsTillNow.filter(
      productId => importScheduledProductIds.includes(productId),
    ).length;

    if (newCurrImportedProductsCount === currImportedProductsCount)
      return undefined;

    setCurrImportedProductsCount(newCurrImportedProductsCount);
    const animationControls = animate(
      currImportedProductsCount,
      newCurrImportedProductsCount,
      {
        duration: 300,
        onUpdate: newVal => {
          counterRef.current!.innerText = new Intl.NumberFormat(
            navigator.language || 'en-US',
          ).format(Math.ceil(newVal));
        },
      },
    );

    return () => {
      animationControls.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImportRes]);

  const isThereAnyInProgressJobs = useMemo(
    () =>
      nonSucceededProductSelectionData.filter(p => p.status === 'InProcess')
        .length > 0,
    [nonSucceededProductSelectionData],
  );

  return (
    <Suspense fallback={null}>
      <div className='relative min-h-10 overflow-hidden bg-white shadow-md sm:rounded-lg dark:bg-gray-800'>
        <div className='divide-y px-4 dark:divide-gray-700'>
          <div className='flex flex-col space-y-3 py-3 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0'>
            <div className='flex flex-1 items-center space-x-4'>
              <h5 data-testid='imported-product-count'>
                <span className='text-gray-500'>
                  Successfully Imported Products:
                </span>{' '}
                <span className='dark:text-white'>
                  <b ref={counterRef}>
                    {new Intl.NumberFormat(
                      navigator.language || 'en-US',
                    ).format(currImportedProductsCount)}
                  </b>
                </span>{' '}
                <span className='text-gray-500'>|</span>{' '}
                <span className='text-gray-500'>Status:</span>{' '}
                <span className='dark:text-white'>
                  <b>
                    {isThereAnyInProgressJobs ? 'In Progress' : 'Completed'}
                  </b>
                </span>
              </h5>
            </div>
          </div>
          <ProductImports />
        </div>
      </div>
    </Suspense>
  );
}
