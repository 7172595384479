import { map, split } from 'lodash-es';
import { useProductsSelectionApi } from '../../services/queries.service';

export default function ImportedProductsCount() {
  const { data: productSelectionData } = useProductsSelectionApi();
  const selectedProducts = new Set(
    map(productSelectionData, id => split(id, '_')[3]),
  ).size;

  return (
    <div className='flex flex-col space-y-3 py-3 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0'>
      <div className='flex flex-1 items-center space-x-4'>
        <h5 data-testid='imported-product-count'>
          <span className='text-gray-500'>Imported Products:</span>{' '}
          <span className='dark:text-white'>
            {new Intl.NumberFormat(navigator.language || 'en-US').format(
              selectedProducts,
            )}
          </span>
        </h5>
      </div>
    </div>
  );
}
