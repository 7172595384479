import {
  ComponentProps,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  type RowSelectionState,
  flexRender,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { usePendingImagesQuery } from '../../../services/queries.service';
import { useImportImagesMutation } from '../../../services/mutations.service';
import ImportImagesBtn from './ImportImagesBtn';
import DownloadZipBtn from './DownloadZipBtn';

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  name,
  checked,
  ...rest
}: {
  indeterminate?: boolean;
} & ComponentProps<'input'>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <div className='flex items-center'>
      <label htmlFor={name}>
        <input
          aria-checked={indeterminate ? 'mixed' : checked}
          type='checkbox'
          ref={ref}
          name={name}
          checked={checked}
          className={classNames(
            className,
            'size-4 rounded border-gray-300 bg-gray-100 text-primary-600 focus:ring-2 focus:ring-primary-500 disabled:opacity-70 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600 disabled:text-gray-900 dark:disabled:text-gray-400',
          )}
          {...rest}
        />
        <span className='sr-only'>Checkbox</span>
      </label>
    </div>
  );
}

function PendingImageImportTab() {
  const { data } = usePendingImagesQuery();
  const importImageMutation = useImportImagesMutation();

  const columns = useMemo<ColumnDef<(typeof data)[number]>[]>(
    () => [
      {
        enableSorting: false,
        accessorKey: 'title',
        id: 'title',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ table }) => (
          <div
            style={
              {
                // Since rows ares flattened by default,
                // we can use the row.depth property
                // and paddingLeft to visually indicate the depth
                // of the row
                // paddingLeft: `${row.depth * 2}rem`,
              }
            }
            className='flex items-start gap-2 text-gray-900 dark:text-white'
          >
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              disabled={importImageMutation.isPending}
              onChange={({ target: { checked: isNewChecked } }) => {
                table.toggleAllRowsSelected(isNewChecked);
              }}
            />{' '}
            <div className='flex flex-row flex-wrap items-center justify-start gap-2'>
              <span>Title</span>
            </div>
          </div>
        ),
        footer: props => props.column.id,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows ares flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            }}
            className='flex items-start gap-2 text-gray-900 dark:text-white'
          >
            <IndeterminateCheckbox
              disabled={importImageMutation.isPending}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={({ target: { checked: isNewChecked } }) => {
                row.toggleSelected(isNewChecked);
              }}
            />{' '}
            <div className='flex flex-row flex-wrap items-center justify-start gap-2'>
              <span>{getValue<string>()}</span>
            </div>
          </div>
        ),
      },
      {
        enableSorting: false,
        accessorKey: 'updatedAt',
        header: 'Updated Date',
        footer: props => props.column.id,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ getValue }) => (
          <div className='flex items-center gap-2'>
            <svg
              className='size-4 text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z'
              />
            </svg>
            <p className='text-sm font-medium text-gray-500 dark:text-gray-400'>
              {new Date(getValue<string>()).toLocaleDateString(
                navigator.language || 'en-US',
              )}
            </p>
          </div>
        ),
      },
      {
        enableSorting: false,
        id: 'imagesCount',
        accessorFn: row => row.img_src.length,
        // accessorKey: 'img_src',
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => (
          <span className='block grow text-end'>Newly Added Images</span>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        footer: props => props.column.id,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ getValue }) => (
          <span className='block text-end'>{getValue<any[]>()}</span>
        ),
      },
    ],
    [importImageMutation.isPending],
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row: (typeof data)[number]) => row.dev_product_id,
    enableRowSelection: true,
    enableExpanding: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
    enableMultiSort: false,
  });

  return (
    <>
      <div className='flex flex-col space-y-3 py-3 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0'>
        <div className='flex flex-1 items-center justify-between space-x-4'>
          <h5 data-testid='imported-product-count'>
            <span className='text-gray-500'>Pending Imports:</span>{' '}
            <span className='dark:text-white'>
              <b>
                {new Intl.NumberFormat(navigator.language || 'en-US').format(
                  data.length,
                )}
              </b>
            </span>
          </h5>
          <div className='flex flex-row items-center justify-start gap-2'>
            <DownloadZipBtn
              selectedRows={Object.keys(rowSelection).filter(
                key => rowSelection[key],
              )}
              disabled={
                data.length === 0 ||
                importImageMutation.isPending ||
                Object.keys(rowSelection).filter(key => rowSelection[key])
                  .length === 0
              }
            />
            <ImportImagesBtn
              onClick={() => {
                importImageMutation.mutate(
                  Object.keys(rowSelection)
                    .filter(key => rowSelection[key])
                    .map(
                      productId =>
                        data.find(item => item.dev_product_id === productId)!,
                    )
                    .filter(item => item != null),
                  {
                    onSuccess: () => {
                      table.resetRowSelection(false);
                      toast.success('Images imported successfully!');
                    },
                  },
                );
              }}
              isLoading={importImageMutation.isPending}
              disabled={
                data.length === 0 ||
                Object.keys(rowSelection).filter(key => rowSelection[key])
                  .length === 0
              }
            />
          </div>
        </div>
      </div>

      <table className='w-full text-left text-sm text-gray-500 dark:text-gray-400'>
        <thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  scope='col'
                  className='cursor-pointer px-4 py-3 [&_.sortIcon]:invisible hover:text-gray-900 dark:hover:text-white [&_.sortIcon]:hover:visible'
                >
                  {header.isPlaceholder ? null : (
                    <div className='flex flex-row items-center justify-start'>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-testid='product-table-body'>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              id={row.id}
              className={classNames(
                'border-b hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-700',
                !row.getIsExpanded()
                  ? 'text-gray-500 dark:text-gray-400'
                  : 'text-gray-900 dark:text-white',
              )}
            >
              {row
                .getVisibleCells()
                .map(cell => (
                  <td
                    className='whitespace-nowrap px-4 py-2 font-medium'
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))
                .filter(Boolean)}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default function SuspendedPendingImageImportTab() {
  return (
    <div className='relative min-h-10 overflow-hidden bg-white shadow-md sm:rounded-lg dark:bg-gray-800'>
      <div className='divide-y px-4 pb-4 dark:divide-gray-700'>
        <Suspense
          fallback={
            <div className='flex flex-col items-center justify-between py-24'>
              <div role='status'>
                <svg
                  aria-hidden='true'
                  className='size-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          }
        >
          <PendingImageImportTab />
        </Suspense>
      </div>
    </div>
  );
}
