import { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { useImportedProductsQuery } from '../../services/queries.service';
import { ProductsGqlResProductNode } from '../../services/IResponses.types';

export default function usePendingToImportImages(
  row: Row<ProductsGqlResProductNode>,
) {
  const { data } = useImportedProductsQuery();
  const currImportedProductMedia = useMemo(
    () =>
      data.find(item => item.dev_product_id === row.original.node.id)?.media,
    [data, row.original.node.id],
  );
  return useMemo(() => {
    if (!currImportedProductMedia || !row.original.node.images) return [];

    const devStoreImages = row.original.node.images?.edges.map(
      imageEdge => imageEdge.node.url,
    );
    return devStoreImages.filter(
      devStoreImage => !currImportedProductMedia[devStoreImage],
    );
  }, [currImportedProductMedia, row.original.node.images]);
}
