import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type State<S> = [S, Dispatch<SetStateAction<S>>];
export type ResetOtpState = State<{ otp: number; email: string } | null>;
const ResetOtpContext = createContext<ResetOtpState>(undefined as any);

export const ResetOtpContextProvider = ResetOtpContext.Provider;

export default function useResetOtpContext() {
  const resetOtp = useContext(ResetOtpContext);

  if (typeof resetOtp === 'undefined') {
    throw Error('ResetOtp must be used in ResetOtpContextProvider');
  }

  return resetOtp;
}
