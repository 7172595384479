import { useField } from 'formik';
import { useMemo } from 'react';

export default function SignInBtn({ onLogin }: { onLogin?: () => void }) {
  const [{ value }, { error }] = useField('storeName');

  // Memoize the disabled state to avoid unnecessary re-renders
  const isDisabled = useMemo(
    () => Boolean(error) || value === 'disabled',
    [error, value],
  );

  return (
    <button
      type='submit'
      className='w-full rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
      disabled={isDisabled}
      onClick={onLogin}
    >
      Sign in
    </button>
  );
}
