import FlowbiteModal from '../../../components/FlowbiteModal';

type Props = {
  onCancel?: () => void;
  onConfirm?: () => void;
  totalVariants: number;
  totalProducts: number;
};

export default function ConfirmationModal({
  onConfirm,
  onCancel,
  totalProducts,
  totalVariants,
}: Props) {
  return (
    <FlowbiteModal
      isOpen
      showCloseBtn={false}
      onClose={onCancel}
      id='import-confirmation-modal'
      data-testid='import-confirmation-modal'
    >
      <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
        Are you sure you want to import{' '}
        <span
          className='font-bold text-gray-900 dark:text-white'
          data-testid='import-count-text'
        >
          {totalVariants} variants ({totalProducts} product(s))
        </span>
        ?
      </h3>
      <button
        data-testid='confirm-import-btn'
        type='button'
        className='inline-flex items-center rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
        onClick={onConfirm}
      >
        Yes, I&quot;m sure
      </button>
      <button
        data-testid='import-cancel-btn'
        type='button'
        className='ms-3 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700'
        onClick={onCancel}
      >
        No, cancel
      </button>
    </FlowbiteModal>
  );
}
