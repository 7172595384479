import { Modal } from 'flowbite';
import {
  type ComponentProps,
  type PropsWithChildren,
  useEffect,
  useRef,
} from 'react';
import classNames from 'classnames';

export type DownloadProgressModelProps = PropsWithChildren<
  {
    isOpen: boolean;
    closable?: boolean;
    onClose?: () => void;
    modalBodyProps?: ComponentProps<'div'>;
    showCloseBtn?: boolean;
  } & ComponentProps<'div'>
>;

export default function FlowbiteModal({
  isOpen,
  closable = true,
  onClose,
  children,
  className,
  modalBodyProps,
  showCloseBtn = closable,
  id = 'popup-modal',
  ...containerProps
}: DownloadProgressModelProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<Modal | null>(null);

  useEffect(() => {
    const modal = new Modal(containerRef.current, {
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable,
    });
    modal.init();
    modalRef.current = modal;

    return () => {
      modal.hide();
      modal.destroy();
    };
  }, [closable]);

  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;
    if (isOpen) modal.show();
    else modal.hide();
  }, [isOpen]);

  return (
    <div
      id={id}
      tabIndex={-1}
      className={classNames(
        className,
        'fixed inset-x-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0',
      )}
      ref={containerRef}
      {...containerProps}
    >
      <div className='relative max-h-full w-full max-w-md p-4'>
        <div className='relative rounded-lg bg-white shadow dark:bg-gray-700'>
          {closable && showCloseBtn && (
            <button
              type='button'
              className='absolute end-2.5 top-3 ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white'
              onClick={() => onClose?.()}
              data-testid='close-modal-btn'
            >
              <svg
                className='size-3'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 14 14'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                />
              </svg>
              <span className='sr-only'>Close modal</span>
            </button>
          )}
          <div
            {...modalBodyProps}
            className={classNames(
              'p-4 text-center md:p-5',
              modalBodyProps?.className,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
