import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { PropsWithChildren } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

function Fallback({ resetErrorBoundary, error }: FallbackProps) {
  const navigate = useNavigate();

  let title: string = '500 Internal Error';
  let description = 'Something went wrong';
  let shouldCloseApp = false;

  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      title = '401 Authorization Error';

      if (error.config?.baseURL?.includes(process.env.REACT_APP_BACKEND_URL!)) {
        shouldCloseApp = true;
      }
      description = 'You are logged out. Please reopen the app';
    } else if (error.response?.status === 404) {
      description = "Whoops! That page doesn't exist.";
    } else if (error.response?.status) {
      title = `${error.response.status} ${error.response.statusText} Error`;
    }
  }

  return (
    <section className='bg-white dark:bg-gray-900'>
      <div className='mx-auto h-screen max-w-screen-xl grid-cols-2 content-center gap-8 px-4 py-8 md:grid lg:px-6 lg:py-16'>
        <div className='self-center'>
          <h1 className='mb-4 text-2xl font-bold text-primary-600 dark:text-primary-500'>
            {title}
          </h1>
          <p className='mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl lg:mb-10 dark:text-white'>
            {description}
          </p>
          <p className='mb-4 text-gray-500 dark:text-gray-400'>
            Here are some helpful links:
          </p>
          <ul className='flex items-center space-x-4 text-gray-500 dark:text-gray-400'>
            <li>
              <button
                type='button'
                className='underline hover:text-gray-900 dark:hover:text-white'
                onClick={() => {
                  if (shouldCloseApp) {
                    // @ts-ignore
                    window.location = process.env.REACT_APP_TEST_STORE_APP_URL!;
                  } else {
                    navigate('/');
                    resetErrorBoundary();
                  }
                }}
              >
                Reset
              </button>
            </li>
          </ul>
        </div>
        <img
          className='mx-auto mb-4 hidden md:flex'
          src='https://flowbite.s3.amazonaws.com/blocks/marketing-ui/500/500.svg'
          alt={title}
        />
      </div>
    </section>
  );
}

export default function GlobalErrorBoundary({ children }: PropsWithChildren) {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
}
