import {
  DefaultError,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { backendApiInstance } from './api.service';
import useStoreNameContext from '../contexts/StoreNameContext';
import { StoreNamesRes } from './IResponses.types';
import { PRODUCT_IMPORT_COOKIE_NAME } from '../constants';
import { SelectedVariantIdGroup } from './Request.types';
import { PendingImagesRes } from './queries.service';

export const useSaveStoreTokenMutation = () => {
  const [storeName] = useStoreNameContext();
  const queryClient = useQueryClient();

  return useMutation<unknown, DefaultError, string>({
    mutationFn: async token => {
      await backendApiInstance.put('/users/token', {
        storename: storeName,
        permanentToken: token,
      });

      await queryClient.invalidateQueries({ queryKey: ['token'] });
    },
  });
};

export const useStoreNamesMutation = () =>
  useMutation<
    string | string[],
    DefaultError,
    { email: string; password: string }
  >({
    mutationFn: async ({ email, password }) => {
      const { data } = await backendApiInstance.get<StoreNamesRes>(
        '/users/getstoresname',
        {
          params: { email, password },
        },
      );

      if ('message' in data) return data.message;
      return data;
    },
  });

export const useSaveProductSelectionMutation = () => {
  const [storeName] = useStoreNameContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (selectedVariantIds: SelectedVariantIdGroup[]) => {
      const obj = new Map<number, number[]>();

      selectedVariantIds.forEach(([productId, variantId]) => {
        const productIdInt = Number.parseInt(productId, 10);
        const variantIdInt = Number.parseInt(variantId, 10);

        if (!obj.has(productIdInt)) {
          obj.set(productIdInt, [variantIdInt]);
        } else {
          obj.get(productIdInt)!.push(variantIdInt);
        }
      });

      const { data } = await backendApiInstance.post('/products', {
        storeName,
        productData: [...obj.entries()].map(([productId, variantIds]) => ({
          id: productId,
          title: selectedVariantIds.find(
            ([currProductId]) => currProductId === productId.toString(),
          )![2] as string,
          variants: variantIds.map(variantId => ({ id: variantId })),
        })),
      });

      const existingValueStr = localStorage.getItem(PRODUCT_IMPORT_COOKIE_NAME);
      const existingValue = existingValueStr
        ? JSON.parse(existingValueStr)
        : [];
      existingValue.push({
        storeName,
        created_at: new Date().toISOString(),
        totalVariants: selectedVariantIds.length,
      });
      localStorage.setItem(
        PRODUCT_IMPORT_COOKIE_NAME,
        JSON.stringify(existingValue),
      );

      await queryClient.invalidateQueries({
        queryKey: ['productSelections'],
      });

      localStorage.setItem(
        'Imported Variants',
        JSON.stringify(selectedVariantIds),
      );
      return data;
    },
  });
};

export const useForgetPasswordGenerateTokenMutation = () =>
  useMutation({
    mutationFn: async (email: string) => {
      const { data } = await backendApiInstance.get<number>(
        '/users/sendemail',
        {
          params: {
            email,
          },
        },
      );

      return data;
    },
  });

export const useResetPasswordMutation = () =>
  useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const { data } = await backendApiInstance.put(
        '/users/resetpassword',
        {
          password,
        },
        {
          params: {
            email,
          },
        },
      );

      return data;
    },
    throwOnError: false,
  });

export const useImportImagesMutation = () => {
  const [storeName] = useStoreNameContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (productsImagesData: PendingImagesRes) => {
      const reqBody: {
        product_id: string;
        image_src: string;
        variant_id: string | null;
      }[] = [];

      productsImagesData.forEach(item => {
        item.img_src.forEach(imgSrc => {
          const imgSrcToSend = new URL(imgSrc);
          imgSrcToSend.searchParams.set(
            'v',
            imgSrcToSend.searchParams.get('v')?.split(':').at(0) ?? '',
          );

          const currImgUrl = new URL(imgSrc);

          const variantIds =
            currImgUrl.searchParams
              .get('v')
              ?.split(':')
              .at(-1)
              ?.split(',')
              .filter(idStr => !Number.isNaN(Number.parseInt(idStr, 10))) ?? [];

          if (variantIds.length === 0) {
            reqBody.push({
              product_id: item.dev_product_id,
              image_src: imgSrcToSend.toString(),
              variant_id: null,
            });
            return;
          }

          variantIds.forEach(variantIdStrInUrl => {
            const variant = item.variants
              .flat()
              .find(
                currVariant =>
                  currVariant.dev_variant_legacy_id === variantIdStrInUrl,
              );

            reqBody.push({
              product_id: item.dev_product_id,
              image_src: imgSrcToSend.toString(),
              variant_id: variant?.variant_legacy_id ?? null,
            });
          });

          reqBody.push({
            product_id: item.dev_product_id,
            image_src: imgSrcToSend.toString(),
            variant_id: null,
          });
        });
      });

      const { data } = await backendApiInstance.post('/products/appendimage', {
        storeName,
        products_images_data: reqBody,
      });

      queryClient.invalidateQueries({
        queryKey: ['pendingImages', storeName],
      });

      return data;
    },
  });
};
