import { ChangeEventHandler } from 'react';

export default function SearchBox({
  globalFilter,
  onChange,
}: {
  globalFilter: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div className='w-full md:w-1/2'>
      <form className='flex items-center'>
        <label htmlFor='simple-search'>
          <span className='sr-only'>Search</span>
          <div className='relative w-full'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <svg
                aria-hidden='true'
                className='size-5 text-gray-500 dark:text-gray-400'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <input
              type='text'
              id='simple-search'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500'
              placeholder='Search'
              required
              autoComplete='off'
              value={globalFilter}
              onChange={onChange}
            />
          </div>
        </label>
      </form>
    </div>
  );
}
