import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setToken } from './services/api.service';
import ReactQueryClientProvider from './providers/ReactQueryClientProvider';
import GlobalErrorBoundary from './GlobalErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const urlSearchParams = new URLSearchParams(window.location.search);

if (urlSearchParams.has('token')) {
  setToken(urlSearchParams.get('token')!);
} else {
  const tokenFromSession = window.sessionStorage.getItem('token');

  if (tokenFromSession) {
    setToken(tokenFromSession);
  }
}

function ReactQueryDevTools() {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const Ele = lazy(() =>
    import('@tanstack/react-query-devtools').then(d => ({
      default: d.ReactQueryDevtools,
    })),
  );
  return (
    <Suspense fallback={null}>
      <Ele />
    </Suspense>
  );
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalErrorBoundary>
        <ReactQueryClientProvider>
          <App />
          <ReactQueryDevTools />
        </ReactQueryClientProvider>
      </GlobalErrorBoundary>
    </BrowserRouter>
    <ToastContainer autoClose={3_000} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
