import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type State<S> = [S, Dispatch<SetStateAction<S>>];
export type StoreNameState = State<string | null>;
const StoreNameContext = createContext<StoreNameState>(undefined as any);

export const StoreNameContextProvider = StoreNameContext.Provider;

export default function useStoreNameContext() {
  const storeName = useContext(StoreNameContext);

  if (typeof storeName === 'undefined') {
    throw Error('useStoreNameContext must be used in StoreNameContextProvider');
  }

  return storeName;
}
