import { ComponentProps, useEffect, useRef } from 'react';
import classNames from 'classnames';

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  name,
  isParent,
  isAllSelected,
  checked,
  isVariantImported,
  isParentAndAllChildWerePreviouslySelected,
  ...rest
}: {
  isParent: boolean;
  isAllSelected: boolean;
  indeterminate?: boolean;
  isVariantImported: boolean;
  isParentAndAllChildWerePreviouslySelected: boolean;
} & ComponentProps<'input'>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate;
      // const currChecked = checked || (isParent && isAllSelected);
      // ref.current.indeterminate = !currChecked && indeterminate;
    }
  }, [ref, indeterminate]);

  const disabled =
    isVariantImported || isParentAndAllChildWerePreviouslySelected;

  const currChecked =
    checked || (isParent && isAllSelected) || (!isParent && isVariantImported);

  return (
    <div className='flex items-center'>
      <label htmlFor={name}>
        <input
          aria-checked={indeterminate ? 'mixed' : checked}
          disabled={disabled}
          type='checkbox'
          ref={ref}
          name={name}
          className={classNames(
            className,
            'size-4 rounded border-gray-300 bg-gray-100 text-primary-600 focus:ring-2 focus:ring-primary-500 disabled:opacity-70 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600',
            disabled && 'text-gray-900 dark:text-gray-400',
          )}
          checked={currChecked}
          {...rest}
        />
        <span className='sr-only'>checkbox</span>
      </label>
    </div>
  );
}
