import { useEffect, useMemo, useState, useTransition } from 'react';
import classNames from 'classnames';
import { toast, type Id } from 'react-toastify';
import SuspendedProductsTable from './SuspendedProductsTable';
import SuspendedProductImports from '../imports/ProductImports';
import { useUnImportedProductsSelectionApi } from '../../services/queries.service';
import PendingImageImportTab from './PendingImageImportTab';

export default function HomePage() {
  const [, startTransition] = useTransition();
  const { data: productImports } = useUnImportedProductsSelectionApi();
  const isImportJobInProgress = useMemo<boolean>(
    () => productImports.filter(p => p.status === 'InProcess').length > 0,
    [productImports],
  );

  const [currTab, setCurrTab] = useState<
    'products' | 'importJobs' | 'pendingImageImports'
  >(() => (isImportJobInProgress ? 'importJobs' : 'products'));

  useEffect(() => {
    let currToast: Id;
    const newCurrTab = isImportJobInProgress ? 'importJobs' : 'products';

    setCurrTab(oldCurrTab => {
      if (oldCurrTab !== newCurrTab && newCurrTab === 'products') {
        currToast = toast.success('All import jobs completed!', {});
        localStorage.removeItem('Imported Variants');
      }
      return newCurrTab;
    });

    return () => {
      toast.dismiss(currToast);
    };
  }, [isImportJobInProgress]);

  return (
    <>
      <section className='pt-3 md:pt-5'>
        <div className='mx-auto max-w-screen-xl px-4 lg:px-6'>
          <ul className='flex flex-wrap border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400'>
            <li className='me-2'>
              <button
                type='button'
                disabled={isImportJobInProgress}
                onClick={() => startTransition(() => setCurrTab('products'))}
                className={classNames(
                  currTab === 'products'
                    ? 'text-blue-600 bg-gray-100 active dark:bg-gray-800 dark:text-blue-500'
                    : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                  'inline-block p-4 rounded-t-lg',
                )}
              >
                Products
              </button>
            </li>
            <li className='me-2'>
              <button
                type='button'
                onClick={() => startTransition(() => setCurrTab('importJobs'))}
                className={classNames(
                  currTab === 'importJobs'
                    ? 'text-blue-600 bg-gray-100 active dark:bg-gray-800 dark:text-blue-500'
                    : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                  'inline-block p-4 rounded-t-lg',
                )}
              >
                Import Jobs
              </button>
            </li>
            <li className='me-2'>
              <button
                type='button'
                onClick={() =>
                  startTransition(() => setCurrTab('pendingImageImports'))
                }
                className={classNames(
                  currTab === 'pendingImageImports'
                    ? 'text-blue-600 bg-gray-100 active dark:bg-gray-800 dark:text-blue-500'
                    : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                  'inline-block p-4 rounded-t-lg',
                )}
              >
                Pending Image Imports
              </button>
            </li>
          </ul>
        </div>
      </section>
      <section className='pt-3 md:pt-5'>
        <div className='mx-auto max-w-screen-xl px-4 lg:px-6'>
          {/* eslint-disable-next-line no-nested-ternary */}
          {currTab === 'products' ? (
            <SuspendedProductsTable />
          ) : currTab === 'importJobs' ? (
            <SuspendedProductImports />
          ) : (
            <PendingImageImportTab />
          )}
        </div>
      </section>
    </>
  );
}
