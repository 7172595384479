/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import noProductImageUrl from 'assets/images/noImage.png';
import {
  assign,
  difference,
  filter,
  fromPairs,
  includes,
  keys,
  map,
  startsWith,
} from 'lodash-es';
import {
  IImportedProduct,
  useProductsQuery,
  useProductsSelectionApi,
} from '../../services/queries.service';
import {
  ProductGqlResVariantNode,
  ProductsGqlRes,
  ProductsGqlResProductNode,
} from '../../services/IResponses.types';
import { SelectedVariantIdGroup } from '../../services/Request.types';
import ImportedProductsCount from './ImportedProductsCount';
import SearchBox from './SearchBox';
import ImportSelectedProductsBtn from './ImportConfirmationModal/ImportSelectedProductsBtn';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import ProductsTableRow from './ProductsTableRow';
import LoadingSpinner from './LoadingSpinner';

type CustomRowSelectionState = Record<
  `variant_${number}_product_${number}_${string}`,
  boolean
>;

const filterProductSelectionApi = (p: IImportedProduct) =>
  !p.status || p.status === 'success';

export default function ProductsTable() {
  const previousProductSelectionData = useRef<
    typeof productSelectionData | null | undefined
  >(undefined);
  const { data: productSelectionData } = useProductsSelectionApi(
    filterProductSelectionApi,
  );

  useEffect(() => {
    if (previousProductSelectionData.current === undefined) {
      previousProductSelectionData.current = null;
    } else {
      const diff = difference(
        previousProductSelectionData.current,
        productSelectionData,
      );
      if (diff.length > 0) {
        setRowSelection(fromPairs(map(diff, variantId => [variantId, false])));
      }
      previousProductSelectionData.current = productSelectionData;
    }
  }, [productSelectionData]);

  const [
    { isPending, data: productsQueryData, isFetching },
    { fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage },
    [globalFilter, setGlobalFilter],
  ] = useProductsQuery();

  const [rowSelectionState, setRowSelection] =
    useState<CustomRowSelectionState>({});
  const rowSelection = useMemo(
    () =>
      assign(
        rowSelectionState,
        fromPairs(map(productSelectionData, variantId => [variantId, true])),
      ),
    [productSelectionData, rowSelectionState],
  );

  const columns = useMemo<
    ColumnDef<ProductsGqlRes['products']['edges'][number]>[]
  >(
    () => [
      {
        enableSorting: true,
        accessorKey: 'node.title',
        header: 'Product',
        cell: ({ row, getValue }) => {
          const currNode = (
            row.original as ProductsGqlResProductNode | ProductGqlResVariantNode
          ).node;

          const imageUrlStr =
            ('image' in currNode && currNode.image?.url) ||
            ('featuredImage' in currNode && currNode.featuredImage?.url) ||
            row.getParentRow()?.original.node?.featuredImage?.url;

          const imageUrl = imageUrlStr
            ? new URL(imageUrlStr)
            : noProductImageUrl;
          if (imageUrlStr) {
            (imageUrl as URL).searchParams.set('width', '20');
            (imageUrl as URL).searchParams.set('height', '20');
          }

          const isParentAndAllChildWerePreviouslySelected =
            'variants' in currNode &&
            row.subRows.every(variantRow =>
              productSelectionData.includes(variantRow.id as any),
            );

          return (
            <div
              style={{
                // Since rows are flattened by default,
                // we can use the row.depth property
                // and paddingLeft to visually indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              }}
              className='flex items-start gap-2 text-gray-900 dark:text-white'
            >
              <IndeterminateCheckbox
                isVariantImported={
                  row.id.startsWith('variant_') &&
                  productSelectionData.includes(row.id as any)
                }
                isParent={'variants' in row.original.node}
                data-testid={`checkbox-${row.id}`}
                checked={row.getIsSelected()}
                isAllSelected={row.getIsAllSubRowsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={({ target: { checked: isNewChecked } }) => {
                  const parentRow = row.getParentRow();

                  if (parentRow) {
                    // This is child row
                    row.toggleSelected(isNewChecked, { selectChildren: true });

                    if (isNewChecked) {
                      if (parentRow.getIsAllSubRowsSelected()) {
                        parentRow.toggleSelected(true);
                      }
                    } else if (
                      parentRow.subRows.every(
                        subRow =>
                          !(subRow.id === row.id
                            ? isNewChecked
                            : subRow.getIsSelected()),
                      )
                    ) {
                      parentRow.toggleSelected(false);
                    }
                  } else {
                    // This is parent row
                    const updater: Record<
                      keyof CustomRowSelectionState,
                      boolean
                    > = {
                      [row.id]: isNewChecked,
                    };
                    row.subRows.forEach(subRow => {
                      updater[subRow.id as any] = isNewChecked;
                    });

                    setRowSelection(selected => ({
                      ...selected,
                      ...updater,
                    }));
                  }
                }}
                isParentAndAllChildWerePreviouslySelected={
                  isParentAndAllChildWerePreviouslySelected
                }
              />{' '}
              <div className='flex flex-row flex-wrap items-center justify-start gap-2'>
                <img
                  loading='lazy'
                  height={20}
                  width={20}
                  className='inline-block size-5 rounded'
                  alt='Featured Product'
                  src={imageUrl.toString()}
                />
                <span>{getValue<string>()}</span>
              </div>
            </div>
          );
        },
        footer: props => props.column.id,
      },
      {
        id: 'totalVariants',
        accessorFn: row => row.node.variants?.edges.length ?? 0,
        cell: info => {
          const rowData = info.row.original as
            | ProductsGqlResProductNode
            | ProductGqlResVariantNode;

          return !('variants' in rowData.node) ? (
            ''
          ) : (
            <button
              data-testid={`row-expander-${info.row.id}`}
              onClick={info.row.getToggleExpandedHandler()}
              type='button'
              className='flex flex-row items-center justify-center gap-3'
            >
              <span>{info.getValue() as number} variants</span>
              {info.row.getIsExpanded() ? (
                <svg
                  className='size-6'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m16 14-4-4-4 4'
                  />
                </svg>
              ) : (
                <svg
                  className='size-6 shrink-0'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </button>
          );
        },
        header: () => <span>Total Variants</span>,
        footer: props => props.column.id,
      },
      {
        id: 'price',
        accessorFn: ({
          node,
        }: ProductsGqlResProductNode | ProductGqlResVariantNode) =>
          'variants' in node ? node.variants.edges[0].node.price : node.price,
        header: () => 'Price',
        footer: props => props.column.id,
        cell: info => (
          <span className='text-gray-900 dark:text-white'>
            {(info.getValue() as any)
              ? new Intl.NumberFormat(navigator.language || 'en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number.parseFloat(info.getValue<string>()))
              : ''}
          </span>
        ),
      },
      {
        id: 'costPrice',
        accessorFn: ({
          node,
        }: ProductsGqlResProductNode | ProductGqlResVariantNode) =>
          'variants' in node
            ? node.variants.edges[0].node.inventoryItem.unitCost?.amount ?? null
            : node.inventoryItem.unitCost?.amount ?? null,
        header: () => 'Cost Price',
        footer: props => props.column.id,
        cell: info => (
          <span className='text-gray-900 dark:text-white'>
            {(info.getValue() as any)
              ? new Intl.NumberFormat(navigator.language || 'en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number.parseFloat(info.getValue<string>()))
              : ''}
          </span>
        ),
      },
      {
        id: 'sku',
        accessorFn: ({
          node,
        }: ProductsGqlResProductNode | ProductGqlResVariantNode) =>
          'variants' in node
            ? node.variants.edges[0].node.sku ?? null
            : node.sku ?? null,
        header: () => 'SKU',
        footer: props => props.column.id,
        cell: info => (
          <span className='text-gray-900 dark:text-white'>
            {info.getValue() as any}
          </span>
        ),
      },
      {
        id: 'productType',
        accessorFn: row => row.node.productType ?? '',
        header: () => 'Product Type',
        footer: props => props.column.id,
      },
      {
        id: 'vendor',
        accessorFn: row => row.node.vendor ?? '',
        header: () => 'Vendor',
        footer: props => props.column.id,
      },
    ],
    [productSelectionData],
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data: productsQueryData?.products.edges ?? [],
    columns,
    state: {
      expanded,
      globalFilter,
      rowSelection,
    },
    // getExpandedRowModel,
    getRowId: (row: ProductsGqlRes['products']['edges'][number]) =>
      row.node.legacyResourceId,
    enableRowSelection: true,
    enableSubRowSelection: true,
    enableExpanding: true,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    manualFiltering: true,
    // globalFilterFn: 'includesString',
    onExpandedChange: setExpanded,
    getSubRows: row => {
      const { node } = row as
        | ProductsGqlResProductNode
        | ProductGqlResVariantNode;

      return 'variants' in node ? (node.variants.edges as any) : undefined;
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    enableMultiSort: false,
    // manualSorting: true,
  });

  const selectedVariants: SelectedVariantIdGroup[] = useMemo(
    () =>
      map(
        filter(
          keys(rowSelection),
          id =>
            !startsWith(id, 'product_') &&
            rowSelection[id] &&
            !includes(productSelectionData, id),
        ),
        (id: keyof CustomRowSelectionState) => {
          const [, variantId, , productId, ...productTitleStrArr] =
            id.split('_');
          return [
            productId,
            variantId,
            productTitleStrArr.join('_'),
          ] as SelectedVariantIdGroup;
        },
      ) as any as SelectedVariantIdGroup[],
    [productSelectionData, rowSelection],
  );

  const isLoading = isPending || isFetching;

  return (
    <div className='relative min-h-10 overflow-hidden bg-white shadow-md sm:rounded-lg dark:bg-gray-800'>
      <div className='divide-y px-4 dark:divide-gray-700'>
        <ImportedProductsCount />
        <div className='flex flex-col items-center justify-between space-y-3 border-b py-4 md:flex-row md:space-x-4 md:space-y-0 dark:border-gray-700'>
          <SearchBox
            globalFilter={globalFilter}
            onChange={event => {
              setGlobalFilter(event.target.value);
            }}
          />
          <ImportSelectedProductsBtn
            disabled={isLoading}
            selectedVariants={selectedVariants}
            onImportSuccess={() => {
              table.resetRowSelection(true);
            }}
          />
        </div>
      </div>
      <div className='relative overflow-x-auto'>
        {isLoading && <LoadingSpinner />}
        <table className='min-h-32 w-full text-left text-sm text-gray-500 dark:text-gray-400'>
          <thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const headerSortOrder = header.column.getIsSorted();
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      scope='col'
                      onClick={() => {
                        const colSortedOrder = header.column.getIsSorted();
                        table.setSorting([
                          {
                            id: header.column.id,
                            desc: colSortedOrder && colSortedOrder === 'asc',
                          },
                        ]);
                      }}
                      className={classNames(
                        'cursor-pointer px-4 py-3 hover:text-gray-900 dark:hover:text-white [&_.sortIcon]:invisible [&_.sortIcon]:hover:visible',
                        headerSortOrder && '!text-gray-900 dark:!text-white',
                      )}
                    >
                      {header.isPlaceholder ? null : (
                        <div className='flex flex-row items-center justify-start'>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody data-testid='product-table-body'>
            {table.getRowModel().rows.map(row => (
              <ProductsTableRow
                key={row.id}
                productSelectionData={productSelectionData}
                row={row}
              />
            ))}
          </tbody>
        </table>
      </div>
      <nav
        className='flex flex-col items-center justify-between space-y-3 p-4 md:flex-row md:space-y-0'
        aria-label='Table navigation'
      >
        <span className='text-sm font-normal text-gray-500 dark:text-gray-400'>
          Selected{' '}
          <span className='font-semibold text-gray-900 dark:text-white'>
            {selectedVariants.length}
          </span>{' '}
          product variant(s)
        </span>
        <ul className='inline-flex items-stretch -space-x-px'>
          <li>
            <button
              type='button'
              disabled={!hasPreviousPage}
              onClick={() => fetchPreviousPage()}
              className='ml-0 flex h-full items-center justify-center rounded-l-lg border border-gray-300 bg-white px-3 py-1.5 text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Previous</span>
              <svg
                className='size-5'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </li>
          <li>
            <button
              type='button'
              disabled={!hasNextPage}
              onClick={() => fetchNextPage()}
              className='flex h-full items-center justify-center rounded-r-lg border border-gray-300 bg-white px-3 py-1.5 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Next</span>
              <svg
                className='size-5'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
