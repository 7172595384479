import classNames from 'classnames';
import { flexRender, Row } from '@tanstack/react-table';
import { ProductsGqlResProductNode } from '../../services/IResponses.types';
import usePendingToImportImages from './usePendingToImportImages';

type Props = {
  row: Row<ProductsGqlResProductNode>;
  productSelectionData: `variant_${number}_product_${number}_${string}`[];
};

export default function ProductsTableRow({ row, productSelectionData }: Props) {
  const pendingToImportImages = usePendingToImportImages(row);

  return (
    <tr
      key={row.id}
      id={row.id}
      className={classNames(
        'border-b hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-700',
        !row.getIsExpanded()
          ? 'text-gray-500 dark:text-gray-400'
          : 'text-gray-900 dark:text-white',
        ((!row.getParentRow() &&
          row.getIsAllSubRowsSelected() &&
          productSelectionData.includes(row.id as unknown as any)) ||
          (row.getParentRow() &&
            productSelectionData.includes(row.id as unknown as any))) &&
          '[&_*]:!text-gray-600 dark:[&_*]:!text-gray-400',
        row.getIsSelected() && 'selected',
        pendingToImportImages.length > 0 && '!bg-gray-200 dark:!bg-gray-600',
      )}
    >
      {row
        .getVisibleCells()
        .map(cell =>
          cell.column.id === 'totalVariants' &&
          cell.row.subRows.length === 0 ? null : (
            <td
              className='whitespace-nowrap px-4 py-2 font-medium'
              key={cell.id}
              colSpan={
                cell.row.subRows.length === 0 && cell.column.id === 'node_title'
                  ? 2
                  : 1
              }
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ),
        )
        .filter(Boolean)}
    </tr>
  );
}
